/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';
import Container from 'components/Container';

const validationSchema = yup.object({
  company: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid company')
    .max(20, 'Please enter a valid company')
    .required('Please specify your company'),
  job: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid job')
    .max(20, 'Please enter a valid job')
    .required('Please specify your job'),
  mobile: yup
    .string()
    .trim()
    .min(8, 'Please enter a valid mobile')
    .max(20, 'Please enter a valid mobile')
    .required('Please specify your mobile'),
  name: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid full name')
    .max(50, 'Please enter a valid full name')
    .required('Please specify your full name'),
  message: yup
    .string()
    .trim()
    .required('Please specify your message'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required'),
});

const Form = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [submitted, setSubmitted] = React.useState(false);
  const initialValues = {
		company: '',
		job:'',
    name: '',
		mobile: '',
		servers: '3-',
		developers: '0-',
    message: '',
    email: '',
  };

  const onSubmit = (values) => {
		  // Default options are marked with *
		fetch("https://flomesh.io:1337/results", {
			body: JSON.stringify(values), // must match 'Content-Type' header
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			// credentials: 'same-origin', // include, same-origin, *omit
			headers: {
			  'content-type': 'application/json'
			},
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, cors, *same-origin
			redirect: 'follow', // manual, *follow, error
			referrer: 'no-referrer', // *client, no-referrer
		}).then(response => {
			let data = response.json();
			setSubmitted(true);
		}).catch(error => {
			console.log("$$error:")
			console.log(error)
		});
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
			{submitted?(
				<Container>
					<Box sx={{ display: 'flex',justifyContent:'center' }}>
						<Box
							component={Avatar}
							width={60}
							height={60}
							bgcolor={green[200]}
							color={green[500]}
						>
							<CheckIcon/>
						</Box>
						<Typography
							variant={'h4'}
							sx={{ fontWeight: 700,paddingLeft:'15px',paddingTop:'10px'}}
							gutterBottom
							align={'center'}
						>
					
							Thanks for submitted
						</Typography>
					</Box>
				</Container>
			):(
				<Box>
					<Box marginBottom={2}>
						<Typography
							variant={'h4'}
							sx={{ fontWeight: 700 }}
							gutterBottom
							align={'center'}
						>
							Contact us
						</Typography>
						<Typography color="text.secondary" align={'center'}>
						</Typography>
					</Box>
					<Box
						maxWidth={600}
						margin={'0 auto'}
						component={'form'}
						onSubmit={formik.handleSubmit}
						sx={{
							'& .MuiOutlinedInput-root.MuiInputBase-multiline': {
								padding: 0,
							},
							'& .MuiOutlinedInput-input': {
								background: theme.palette.background.paper,
								padding: 2,
							},
						}}
					>
						<Grid container spacing={isMd ? 4 : 2}>
							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									Company
								</Typography>
								<TextField
									placeholder="Your company"
									variant="outlined"
									size="medium"
									name="company"
									fullWidth
									type="text"
									value={formik.values.company}
									onChange={formik.handleChange}
									error={formik.touched.company && Boolean(formik.errors.company)}
									helperText={formik.touched.company && formik.errors.company}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									Job
								</Typography>
								<TextField
									placeholder="Your job"
									variant="outlined"
									size="medium"
									name="job"
									fullWidth
									type="text"
									value={formik.values.job}
									onChange={formik.handleChange}
									error={formik.touched.job && Boolean(formik.errors.job)}
									helperText={formik.touched.job && formik.errors.job}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									Full name
								</Typography>
								<TextField
									placeholder="Your full name"
									variant="outlined"
									size="medium"
									name="name"
									fullWidth
									type="text"
									value={formik.values.name}
									onChange={formik.handleChange}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									E-mail
								</Typography>
								<TextField
									placeholder="Your e-mail address"
									variant="outlined"
									size="medium"
									name="email"
									fullWidth
									type="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									Mobile
								</Typography>
								<TextField
									placeholder="Your mobile"
									variant="outlined"
									size="medium"
									name="mobile"
									fullWidth
									type="text"
									value={formik.values.mobile}
									onChange={formik.handleChange}
									error={formik.touched.mobile && Boolean(formik.errors.mobile)}
									helperText={formik.touched.mobile && formik.errors.mobile}
								/>
							</Grid>
							<Grid item xs={6}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									Company servers size
								</Typography>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									value={formik.values.servers}
									onChange={formik.handleChange}
									name="servers"
								>
									<FormControlLabel value="3-" control={<Radio />} label="Less than 3 servers" />
									<FormControlLabel value="3-10" control={<Radio />} label="3 - 10 servers" />
									<FormControlLabel value="10-50" control={<Radio />} label="10 - 50 servers" />
									<FormControlLabel value="50+" control={<Radio />} label="More than 50 servers" />
								</RadioGroup>
							</Grid>
							<Grid item xs={6}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									Company developers size
								</Typography>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									value={formik.values.developers}
									onChange={formik.handleChange}
									name="developers"
								>
									<FormControlLabel value="0-" control={<Radio />} label="0 developers" />
									<FormControlLabel value="10-" control={<Radio />} label="Less than 10 developers" />
									<FormControlLabel value="10-50" control={<Radio />} label="10 - 50 developers" />
									<FormControlLabel value="50+" control={<Radio />} label="More than 50 developers" />
								</RadioGroup>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									color="text.primary"
									fontWeight={700}
									gutterBottom
								>
									Message
								</Typography>
								<TextField
									placeholder="Your question about our services"
									variant="outlined"
									name="message"
									fullWidth
									multiline
									rows={4}
									value={formik.values.message}
									onChange={formik.handleChange}
									error={formik.touched.message && Boolean(formik.errors.message)}
									helperText={formik.touched.message && formik.errors.message}
								/>
							</Grid>
							<Grid item container justifyContent="center" xs={12}>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									size="large"
								>
									Send
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
    </Box>
  );
};

export default Form;
